@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  .text-2xs { font-size: 0.625rem } // 10px
  .text-3xs { font-size: 0.5rem } // 8px

  .min-w-2xs { min-width: 16rem } //  256px
  .min-w-xs  { min-width: 20rem } //  320px
  .min-w-sm  { min-width: 24rem } //  384px
  .min-w-md  { min-width: 28rem } //  448px
  .min-w-lg  { min-width: 32rem } //  512px
  .min-w-xl  { min-width: 36rem } //  576px
  .min-w-2xl { min-width: 42rem } //  672px
  .min-w-3xl { min-width: 48rem } //  768px
  .min-w-4xl { min-width: 56rem } //  896px
  .min-w-5xl { min-width: 64rem } // 1024px
  .min-w-6xl { min-width: 72rem } // 1152px

  .w-18 { width: 4.5rem }

  .text-red-price   { @apply text-red-300 }
  .text-green-price { @apply text-green-500 }
  .text-gray-price  { @apply text-gray-500 }

  .text-gray          { @apply text-gray-700 }
  .text-gray-light    { @apply text-gray-600 }
  .text-gray-lighter  { @apply text-gray-500 }
  .text-gray-lightest { @apply text-gray-400 }

  .bg-light { @apply bg-gray-lightest }             // 預設背景色
  .border-bg-light { @apply border-gray-lightest }

  .text-link { @apply text-primary-normal }
  .text-link:hover { @apply text-gray-900 }
}

